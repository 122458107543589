import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	BONUS,
	GET_PLAYERS_BONUS_LIST,
	GET_BONUS_LIST_FILTERS,
	GET_CREATE_BONUS_FILTERS,
	GET_CAMPAIGN_CREATE_FILTERS,
	CREATE_BONUS,
	GET_PLAYERS_BONUS_LIST_FILTERS,
	GET_BONUS_LIST,
	UPDATE_BONUS,
	GET_BONUS_CAMPAIGN_LIST_FILTERS,
	GET_CAMPAIGN_LIST,
	UPDATE_CAMPAIGN,
	GRANT_PLAYER_BONUS,
	GET_BONUS_BY_ID,
	GET_CAMPAIGN_PLAYERS,
	CANCEL_PLAYER_BONUS,
	GET_CAMPAIGN_BY_ID,
	UPDATE_CAMPAIGN_STATUS,
	SUGGEST_QUICK_BETS
} from "app/constants";
import { prepareHeaders } from "utils/helpers";

export const bonusApiSlice = createApi({
	reducerPath: "bonus",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${BONUS}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getBonusListFilters: query({
				query: () => GET_BONUS_LIST_FILTERS
			}),
			getPlayerBonusListFilters: query({
				query: () => GET_PLAYERS_BONUS_LIST_FILTERS
			}),
			getBonusCampaignListFilters: query({
				query: () => GET_BONUS_CAMPAIGN_LIST_FILTERS
			}),
			getBonusCampaignCreateFilters: query({
				query: () => GET_CAMPAIGN_CREATE_FILTERS
			}),
			getCreateBonusFilters: query({
				query: () => GET_CREATE_BONUS_FILTERS
			}),
			getPlayersBonusList: mutation({
				query: body => ({
					url: GET_PLAYERS_BONUS_LIST,
					method: "POST",
					body
				})
			}),
			cancelPlayerBonus: mutation({
				query: ({ playerBonusId }) => ({
					url: `${CANCEL_PLAYER_BONUS}/${playerBonusId}`,
					method: "PUT"
				})
			}),
			getBonusListWithFilters: mutation({
				query: body => ({
					url: GET_BONUS_LIST,
					method: "POST",
					body
				})
			}),
			createBonus: mutation({
				query: body => {
					return {
						url: CREATE_BONUS,
						method: "POST",
						body
					};
				}
			}),
			updateBonus: mutation({
				query: ({ id, isActive }) => {
					return {
						url: `${UPDATE_BONUS}/${id}`,
						method: "PUT",
						body: { isActive }
					};
				}
			}),
			getCampaignList: mutation({
				query: body => {
					return {
						url: GET_CAMPAIGN_LIST,
						method: "POST",
						body
					};
				}
			}),
			updateCampaignById: mutation({
				query: ({ id, ...body }) => {
					return {
						url: `${UPDATE_CAMPAIGN}/${id}`,
						method: "POST",
						body
					};
				}
			}),
			updateCampaignStatus: mutation({
				query: ({ id, isActive }) => {
					return {
						url: `${UPDATE_CAMPAIGN_STATUS}/${id}`,
						method: "PUT",
						body: { isActive }
					};
				}
			}),
			createCampaign: mutation({
				query: body => {
					return {
						url: `${GRANT_PLAYER_BONUS}`,
						method: "POST",
						body
					};
				}
			}),
			getBonusById: mutation({
				query: id => {
					return {
						url: `${GET_BONUS_BY_ID}/${id}`,
						method: "GET"
					};
				}
			}),
			getCampaignPlayers: mutation({
				query: id => {
					return {
						url: `${GET_CAMPAIGN_PLAYERS}/${id}`,
						method: "GET"
					};
				}
			}),
			getCampaignById: query({
				query: id => {
					return {
						url: `${GET_CAMPAIGN_BY_ID}/${id}`,
						method: "GET"
					};
				}
			}),
			suggestQuickBets: mutation({
				query: body => {
					return {
						url: `${SUGGEST_QUICK_BETS}`,
						method: "POST",
						body
					};
				}
			})
		};
	}
});

export const {
	useGetBonusListFiltersQuery,
	useGetCreateBonusFiltersQuery,
	useGetBonusCampaignListFiltersQuery,
	useGetBonusCampaignCreateFiltersQuery,
	useGetPlayersBonusListMutation,
	useGetBonusListWithFiltersMutation,
	useGetCampaignListMutation,
	useUpdateCampaignByIdMutation,
	useUpdateCampaignStatusMutation,
	useCancelPlayerBonusMutation,
	useCreateBonusMutation,
	useSuggestQuickBetsMutation,
	useUpdateBonusMutation,
	useGetPlayerBonusListFiltersQuery,
	useCreateCampaignMutation,
	useGetBonusByIdMutation,
	useGetCampaignPlayersMutation,
	useGetCampaignByIdQuery
} = bonusApiSlice;
