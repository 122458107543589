import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";

export function useFilters(useQuery, params, defaultSelected, overideData) {
	const [selectedFiltersGroup, setSelectedFiltersGroup] = useState({});
	const [isFiltersChanged, setFiltersChanged] = useState(false);

	const queryData = useQuery(params || {})?.data || {};
	const isLoading = useQuery(params || {})?.isLoading;

	const allQueryData = useMemo(() => ({ ...queryData, ...overideData }), [queryData, overideData]);

	const handleSelectChange = (inputData, type, form, options, customQueryName) => {
		switch (type) {
			case "select": {
				const { name, value } = inputData;
				form?.setFieldValue([name], value);

				setSelectedFiltersGroup(prev => {
					const prevState = { ...prev };
					if (name === "ipTypes") {
						delete prevState.ipAddress;
					}
					return { ...prevState, [name]: value };
				});
				break;
			}
			case "duoselect": {
				const { name, value } = inputData;

				setSelectedFiltersGroup(prevState => {
					return {
						...prevState,
						[name]: value
					};
				});
				break;
			}
			case "tree": {
				const { name, value } = inputData;
				form?.setFieldValue(name, value);
				setSelectedFiltersGroup(prev => ({ ...prev, [name]: value }));
				break;
			}
			case "multiple": {
				const {
					target: { parentname, value, checked }
				} = inputData;
				form?.setFieldValue(parentname, value);
				const selectedDropdownGroupClone = { ...selectedFiltersGroup };
				if (value === "") {
					delete selectedDropdownGroupClone[parentname];
				} else if (value === "all") {
					if (checked) {
						selectedDropdownGroupClone[parentname] = customQueryName
							? allQueryData?.[customQueryName]?.map(item => item.id || item.value)
							: { ...options.additionalData, ...allQueryData }[parentname]?.map(item => item.id || item.value);
					} else {
						delete selectedDropdownGroupClone[parentname];
					}
				} else {
					selectedDropdownGroupClone[parentname] = selectedDropdownGroupClone[parentname] || [];
					if (checked) {
						selectedDropdownGroupClone[parentname] = [...selectedDropdownGroupClone[parentname], value];
					} else if (selectedDropdownGroupClone[parentname].length === 1) {
						delete selectedDropdownGroupClone[parentname];
					} else {
						selectedDropdownGroupClone[parentname] = selectedDropdownGroupClone[parentname].filter(
							item => item !== value
						);
					}
				}
				form.setFieldValue(
					[parentname],
					selectedDropdownGroupClone?.[parentname]?.length ? selectedDropdownGroupClone[parentname] : undefined
				);
				setSelectedFiltersGroup(selectedDropdownGroupClone);
				break;
			}
			case "input": {
				const {
					value: {
						target: { value }
					},
					name
				} = inputData;
				const selectedDropdownGroupClone = { ...selectedFiltersGroup };
				if (!value) {
					delete selectedDropdownGroupClone[name];
				} else {
					selectedDropdownGroupClone[name] = value;
				}
				form?.setFieldValue(name, value);
				setSelectedFiltersGroup({ ...selectedDropdownGroupClone });
				break;
			}
			case "maskedInput": {
				const {
					value: {
						target: { value }
					},
					name
				} = inputData;
				setSelectedFiltersGroup(prev => {
					const selectedDropdownGroupClone = { ...prev };
					form?.setFieldValue(name, value === "_._._._" ? "" : value);
					if (value === "_._._._" || !value) {
						delete selectedDropdownGroupClone[name];
						return { ...selectedDropdownGroupClone };
					}

					return { ...selectedDropdownGroupClone, [name]: value };
				});
				break;
			}
			case "rangePicker": {
				const { value, customKey, name } = inputData;
				const [startDate, endDate] = value;

				form?.setFieldValue(name, value);
				if (customKey) {
					setSelectedFiltersGroup(prev => ({
						...prev,
						[`${customKey}StartDate`]: startDate,
						[`${customKey}EndDate`]: endDate,
						[name]: [startDate, endDate]
					}));
					break;
				}

				setSelectedFiltersGroup(prev => ({ ...prev, startDate, endDate }));
				break;
			}
			case "monthRangePicker": {
				const { value, name } = inputData;
				const [startDate, endDate] = value;
				setSelectedFiltersGroup(prev => ({
					...prev,
					startDate: startDate && moment.tz(startDate, "Asia/Yerevan").startOf("month").format(),
					endDate: endDate && moment.tz(endDate, "Asia/Yerevan").endOf("month").format()
				}));
				form?.setFieldValue(name, value || undefined);
				break;
			}
			case "month": {
				const { value } = inputData;
				const startDate = value ? moment.tz(value, "Asia/Yerevan").startOf("month").format() : undefined;
				const endDate = value ? moment.tz(value, "Asia/Yerevan").endOf("month").format() : undefined;
				// form?.setFieldValue(name, value ? moment(value).format("YYYY-MM") : undefined);
				setSelectedFiltersGroup(prev => ({ ...prev, startDate, endDate }));
				break;
			}
			default: {
				const { name, value, target } = inputData;

				if (target?.type === "checkbox") {
					setSelectedFiltersGroup(prev => ({ ...prev, [target.name]: target.checked }));
				} else {
					setSelectedFiltersGroup(prev => ({ ...prev, [name]: value }));
				}
			}
		}
	};

	useEffect(() => {
		// setting all values , by default
		if (Object.keys(allQueryData).length && defaultSelected) {
			setSelectedFiltersGroup({ ...defaultSelected });
		}
	}, [allQueryData]);

	return [
		allQueryData,
		{ selectedFiltersGroup, setSelectedFiltersGroup, isFiltersChanged, setFiltersChanged },
		handleSelectChange,
		isLoading
	];
}
